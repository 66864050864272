import {
  Col, ConfigProvider, Row, Table,
} from 'antd';
import { LanguageContext } from 'contexts/LanguageContext';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import { SPACE_SIZES } from 'lib/constants';
import { useContext } from 'react';
import { SectionWrapper } from 'storybook-components/sections/SectionWrapper/SectionWrapper';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { useVT } from 'virtualizedtableforantd4';
import { messageSendingTranslations } from '../../translations';
import WarningList from '../WarningList/WarningList';
import DistributionModeRow from './components/DistirbutionModeRow/DistributionModeRow';
import RecipientSearchBar from './components/RecipientSearchBar/RecipientSearchBar';
import { useRecipientOverview } from './useRecipientOverview';

const TABLE_SCROLL_Y = 500;
const RecipientOverview = () => {
  const { tl } = useContext(LanguageContext);
  const {
    dataSource, columns, loading, expandedRowKeys, emptyTableText,
  } = useRecipientOverview();

  const [
    VT,
  ] = useVT(() => ({ scroll: { y: TABLE_SCROLL_Y } }), []);

  return (
    <SectionWrapper
      sectionId="recipientSelector"
      sectionTitle={tl(messageSendingTranslations.recipientSelector)}
      sectionNumber={2}
      isOpen
    >
      <WarningList sectionNr={2} />
      <DistributionModeRow />
      <Row>
        <Col span={24}>
          <RecipientSearchBar />
        </Col>
        <Col span={24}>
          <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={emptyTableText} />}>
            <Table
              className="BasicTable"
              columns={columns}
              loading={{
                spinning: loading,
                indicator: <TableContentLoadingIndicator />,
              }}
              dataSource={dataSource}
              pagination={false}
              rowClassName={r => (r.children ? 'parent-row-level-0' : '')}
              expandable={{
                expandedRowKeys,
                indentSize: 0,
                expandIcon: () => null,
              }}
              scroll={{ y: TABLE_SCROLL_Y }}
              components={VT}
            />
          </ConfigProvider>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default RecipientOverview;
