import Icon from '@ant-design/icons/lib/components/Icon';
import { ICONS } from 'components/icons';
import { LanguageContext } from 'contexts/LanguageContext';
import CheckboxInput from 'elements/Inputs/CheckboxInput/CheckboxInput';
import { useContext } from 'react';
import { searchBarTranslations } from './translations';
import './SearchBar.scss';

interface SearchBarProps {
    searchValue?: string,
    onSearch: (val: string) => void,
    checkboxLabel?: string,
    checkboxValue?: boolean,
    onChangeCheckboxValue?: (value: boolean) => void
    searchPlaceholder?: string,
    checkboxDisabled?: boolean,
    withBottomMargin?: boolean,
    className?: string,
}

const SearchBar = ({
  searchValue,
  onSearch,
  onChangeCheckboxValue,
  checkboxValue,
  checkboxLabel,
  searchPlaceholder,
  checkboxDisabled,
  withBottomMargin,
  className,
}: SearchBarProps) => {
  const { tl } = useContext(LanguageContext);

  const searchBarClassName = `SearchBar ${className || ''} ${withBottomMargin ? 'SearchBarWithBottomMargin' : ''}`;

  return (
    <div
      className={searchBarClassName}
      role="searchbox"
      tabIndex={0}
    >
      <Icon
        component={ICONS.magnifyingGlass}
        className="magnifying-icon"
      />
      <input
        className="search-bar-input"
        value={searchValue}
        onChange={e => onSearch(e.target.value)}
        placeholder={searchPlaceholder || tl(searchBarTranslations.searchPlaceholder)}
      />
      {checkboxLabel
            && (
              <CheckboxInput
                value={checkboxValue}
                onChange={onChangeCheckboxValue}
                label={checkboxLabel}
                className="search-checkbox"
                disabled={checkboxDisabled}
              />
            )}
    </div>

  );
};

export default SearchBar;

SearchBar.defaultProps = {
  checkboxLabel: undefined,
  checkboxValue: undefined,
  onChangeCheckboxValue: () => {},
  searchPlaceholder: undefined,
  checkboxDisabled: false,
  className: undefined,
  searchValue: undefined,
  withBottomMargin: false,
};
